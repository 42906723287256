.login {
    background-color: #f8f8f8;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
}

.login__container {
    padding: 100px;
    text-align: center;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)
}

.login__container > img {
    object-fit: contain;
    height: 100px;
    margin-bottom: 40px;
}

.login__container > button {
    margin-top: 50px;
    text-transform: inherit !important;
    background-color: #0a8d48 !important;
    color: white;
}

@media (max-width: 760px) {
    .login__container {
        padding: 100px 50px;
    }
    .login__text > h1 {
        font-size: 28px;
        width: 100%;
    }
}

@media (max-width: 435px) {
    .login__container {
        padding: 50px 25px;
    }
}